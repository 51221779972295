import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { isMobile } from '../utils/deviceDetect';

const Home = () => {
  const router = useRouter();

  useEffect(() => {
    const redirectPath = isMobile() ? '/h5/init' : '/web/init';
    router.replace(redirectPath);
  }, [router]);

  return null;
};

export default Home;
